<app-card class="sticky top-[76px] z-10">
    <div class="flex gap-4">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Fra år</mat-label>
            <mat-select [(value)]="selectedYearFrom" (valueChange)="setZoom()">
                @for (year of years; track year) {
                <mat-option [value]="year" [disabled]="year > selectedYearTo">{{year}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Til år</mat-label>
            <mat-select [(value)]="selectedYearTo" (valueChange)="setZoom()">
                @for (year of years; track year) {
                <mat-option [value]="year" [disabled]="year < selectedYearFrom">{{year}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
</app-card>
<!-- Oil -->
<div class="grid gap-4 grid-cols-[1fr_400px_1fr] w-full mb-4 mt-4">
    <app-card
        [class]="oilSalesFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
        cardClass="h-full !overflow-visible">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Solgt olie på fastpris til fremtidig levering
        </h3>
        @if(showGraphs) {
        <div echarts [options]="oilSales" (chartInit)="oilSalesInstance = $event" [autoResize]="true"
            class="demo-chart relative" [class]="oilSalesFullScreen ? 'h-[90vh]' : 'aspect-video'">
        </div>
        }
    </app-card>
    <app-card cardClass="h-full">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Lukkede salgsaftaler (Fastpris olie)
        </h3>
        <app-dashboard-table [headers]="getClosedTradesHeaders('m3')" [data]="closedTradesData"></app-dashboard-table>
    </app-card>
    <app-card
        [class]="oilSalesMonthFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
        cardClass="h-full !overflow-visible">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Solgt olie volumen efter salgsmåned
        </h3>
        @if(showGraphs) {
        <div echarts [options]="oilSalesMonth" (chartInit)="oilSalesMonthInstance = $event" [autoResize]="true"
            class="demo-chart relative" [class]="oilSalesMonthFullScreen ? 'h-[90vh]' : 'aspect-video'"></div>
        }
    </app-card>
</div>
<!-- Electricity -->
<div class="grid gap-4 grid-cols-[1fr_400px_1fr] w-full mb-4">
    <app-card
        [class]="electricitySalesFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
        cardClass="h-full !overflow-visible">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Solgt el på fastpris til fremtidig levering
        </h3>
        @if(showGraphs) {
        <div echarts [options]="electricitySales" (chartInit)="electricitySalesInstance = $event" [autoResize]="true"
            class="demo-chart relative" [class]="electricitySalesFullScreen ? 'h-[90vh]' : 'aspect-video'"></div>
        }
    </app-card>
    <app-card cardClass="h-full">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Lukkede salgsaftaler (Fastpris el)
        </h3>
        <app-dashboard-table [headers]="getClosedTradesHeaders('kWh')" [data]="closedTradesData"></app-dashboard-table>
    </app-card>
    <app-card
        [class]="electricitySalesMonthFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
        cardClass="h-full !overflow-visible">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Solgt el volumen efter salgsmåned
        </h3>
        @if(showGraphs) {
        <div echarts [options]="electricitySalesMonth" (chartInit)="electricitySalesMonthInstance = $event"
            [autoResize]="true" class="demo-chart relative"
            [class]="electricitySalesMonthFullScreen ? 'h-[90vh]' : 'aspect-video'"></div>
        }
    </app-card>
</div>
<!-- Gas -->
<div class="grid gap-4 grid-cols-[1fr_400px_1fr] w-full">
    <app-card
        [class]="gasSalesFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
        cardClass="h-full !overflow-visible">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Solgt gas på fastpris til fremtidig levering
        </h3>
        @if(showGraphs) {
        <div echarts [options]="gasSales" (chartInit)="gasSalesInstance = $event" [autoResize]="true"
            class="demo-chart relative" [class]="gasSalesFullScreen ? 'h-[90vh]' : 'aspect-video'"></div>
        }
    </app-card>
    <app-card cardClass="h-full">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Lukkede salgsaftaler (Fastpris gas)
        </h3>
        <app-dashboard-table [headers]="getClosedTradesHeaders('m3')" [data]="closedTradesData"></app-dashboard-table>
    </app-card>
    <app-card
        [class]="gasSalesMonthFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
        cardClass="h-full !overflow-visible">
        <h3 class="font-bold text-xl text-secondary-dark mb-4">
            Solgt gas volumen efter salgsmåned
        </h3>
        @if(showGraphs) {
        <div echarts [options]="gasSalesMonth" (chartInit)="gasSalesMonthInstance = $event" [autoResize]="true"
            class="demo-chart relative" [class]="gasSalesMonthFullScreen ? 'h-[90vh]' : 'aspect-video'"></div>
        }
    </app-card>
</div>