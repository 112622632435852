import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, retry } from 'rxjs';
import { throwError } from 'rxjs';
import { Customer } from '../types';
import { PermissionsService } from './permissions.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    private http: HttpClient,
    private permissions: PermissionsService
  ) {}

  private handleError(error: ErrorEvent) {
    console.error('There was a problem', error)
    return throwError(() => new Error('Oops! Something went wrong. Please try again later.'));
  }

  getCustomers(params: Record<string, string>): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${environment.apiConfig.bffBaseUrl}/api/v1/customers`, {params: params, headers: this.permissions.headers()}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }
}
