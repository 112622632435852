@if (!loginDisplay) {
<main class="flex p-4 items-center justify-center h-full bg-gray">
  <app-card class="min-w-[300px]">
    <div class="flex gap-4 flex-col">
      <div class="flex gap-2 items-center">
        <app-logo />
        <p class="text-xl font-bold text-secondary-dark">{{ title }}</p>
      </div>
      <button (click)="loginRedirect()" mat-flat-button>Log ind med din OK konto</button>
    </div>
  </app-card>
</main>
}

@if (loginDisplay) {
<nav class=" bg-white border-b sticky top-0 left-0 right-0 h-[60px] flex justify-between items-center px-4 z-10">
  <a routerLink="/" class="flex gap-2 items-center">
    <app-logo />
    <p class="text-xl font-bold text-secondary-dark">{{ title }}</p>
  </a>
  <ul class="gap-4 items-center h-full lg:flex hidden">
    @for (item of mainMenuItems; track item) {
    @if (permissions.checkPermission(item.permission) || !item.permission) {
    <li class="h-full">
      <app-main-menu-link [link]="item.link" [text]="item.text"></app-main-menu-link>
    </li>
    }
    }
    <button mat-icon-button [matMenuTriggerFor]="usermenu">
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #usermenu="matMenu">
      <button mat-menu-item [routerLink]="'/profile'">
        <span>Min profil</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <span>Log ud</span>
      </button>
    </mat-menu>
  </ul>

  <div class="block lg:hidden">
    <button mat-icon-button [matMenuTriggerFor]="mobilemenu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #mobilemenu="matMenu">
      @for (item of mainMenuItems; track item) {
      @if (permissions.checkPermission(item.permission) || !item.permission) {
      <button mat-menu-item [routerLink]="item.link">
        <span>{{item.text}}</span>
      </button>
      }
      }
      <mat-divider class="!border-t-gray-darker"></mat-divider>
      <button mat-menu-item [routerLink]="'/profile'">
        <span>Min profil</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <span>Log ud</span>
      </button>
    </mat-menu>
  </div>
</nav>
@if (showSidebar) {
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" class="border-r !border-gray-200 relative !rounded-none !z-0"
    [opened]="showSidebar ? true : false" [fixedInViewport]="true" [fixedBottomGap]="0" [fixedTopGap]="60">
    <div id="teleport"></div>
  </mat-sidenav>
  <main class="min-h-[calc(100vh-60px)] bg-gray relative" [class]="showSidebar ? 'pl-[50px]' : null">
    <div class="fixed top-[70px] z-10 transition-[left] duration-[300ms] ease-out"
      [class]="sidenav.opened ? 'left-[370px]' : 'left-[10px]'">
      @if(showSidebar) {
      <button type="button" class="leading-[1]" mat-icon-button (click)="sidenav.toggle()">
        <span class="icon-sidebar-sm"></span>
      </button>
      }
    </div>
    <div class="p-4 w-full max-w-[1920px] mx-auto">

      @if (!isIframe) {
      <router-outlet />
      }
    </div>
  </main>
</mat-sidenav-container>
} @else {
<div id="teleport" class="sticky top-[60px] left-0 right-0 z-[8]"></div>
<main class="min-h-[calc(100vh-60px)] bg-gray relative" [class]="showSidebar ? 'pl-[50px]' : null">
  <div class="p-4 w-full max-w-[1920px] mx-auto">
    @if (!isIframe) {
    <router-outlet />
    }
  </div>
</main>
}
}