import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})

export class PermissionsService {
  permissions: string[] = []
  token: string | null = null

  constructor(
    private http: HttpClient
  ) {}

  headers() {
    return {
      Authorization: 'bearer ' + this.token
    }
  }

  async waitForToken() {
    return new Promise((resolve) => {
      setInterval(() => {
        if (this.token) resolve(true)
      }, 100);
    })
  }

  async getPermissions() {
    console.debug('getting permissions')
    return new Promise((resolve/*, reject*/) => {
      this.http.get(`${environment.apiConfig.bffBaseUrl}/api/v1/permissions`, {headers: {Authorization: 'bearer ' + this.token}}).subscribe(response => {
        //console.debug('permissions', response)
        this.permissions = response as string[]
        resolve(true)
      })
    })
  }

  checkPermission(permission: string) {
    if (permission) {
      //console.debug(`checking permission ${permission}`, this.permissions.includes(permission))
      return this.permissions.includes(permission)
    }
    return true
  }
}