import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { CardComponent } from "../../../components/card.component";
import { EChartsOption, EChartsType, SliderDataZoomComponentOption, VisualMapComponentOption } from 'echarts';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { HttpClient } from '@angular/common/http';
import { PermissionsService } from '../../../services/permissions.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-net-positions',
  standalone: true,
  imports: [CardComponent, NgxEchartsDirective],
  templateUrl: './net-positions.component.html',
  styleUrl: './net-positions.component.css',
  providers: [provideEcharts()]
})
export class NetPositionsComponent implements OnInit {
  private cdr = inject(ChangeDetectorRef)
  selectedYearFrom = 2023
  selectedYearTo = 2027

  colors = [
    '#ce0011',
    '#25AD8D',
    '#F39F2D',
    '#78A2BB',
  ]

  oilB0NetPosition: EChartsOption = {}
  oilB0NetPositionInstance: EChartsType | null = null
  oilB0NetPositionFullScreen = false
  oilB7NetPosition: EChartsOption = {}
  oilB7NetPositionInstance: EChartsType | null = null
  oilB7NetPositionFullScreen = false
  oilSettings = {
    unit: 'm³',
    grid: {
      left: 60,
      right: 20,
      bottom: 70,
      top: 60,
    }
  }

  electricitySystemNetPosition: EChartsOption = {}
  electricitySystemInstance: EChartsType | null = null
  electricitySystemFullScreen = false
  electricityEpadDk1NetPosition: EChartsOption = {}
  electricityEpadDk1Instance: EChartsType | null = null
  electricityEpadDk1FullScreen = false
  electricityEpadDk2NetPosition: EChartsOption = {}
  electricityEpadDk2Instance: EChartsType | null = null
  electricityEpadDk2FullScreen = false
  electricitySettings = {
    unit: 'kWh',
    grid: {
      left: 100,
      right: 20,
      bottom: 70,
      top: 60,
    }
  }

  gasEtfNetPosition: EChartsOption = {}
  gasEtfInstance: EChartsType | null = null
  gasEtfFullScreen = false
  gasTtfNetPosition: EChartsOption = {}
  gasTtfInstance: EChartsType | null = null
  gasTtfFullScreen = false
  gasSettings = {
    unit: 'm³',
    grid: {
      left: 70,
      right: 20,
      bottom: 70,
      top: 60,
    }
  }

  constructor(
    private http: HttpClient,
    private permissions: PermissionsService
  ){}

  visualMapSettings = {
    orient: 'horizontal',
    top: 30,
    left: 0,
    pieces: [
      {
        min: -0.01,
        max: 0.01,
        color: '#999',
        label: 'Balancerer'
      },
      {
        min: 0.01,
        max: 10000000,
        color: '#25AD8D',
        label: 'Mere indkøbt'
      },
      {
        min: -10000000,
        max: -0.01,
        color: '#ce0011',
        label: 'Mere solgt'
      }
    ],
    outOfRange: {
      color: '#999'
    }
  } as VisualMapComponentOption

  dataZoomStyling = {
    backgroundColor: 'white',
    dataBackground: {
      lineStyle: {
        color: '#ce0011'
      },
      areaStyle: {
        color: '#ce0011',
        opacity: .05
      }
    },
    selectedDataBackground: {
      lineStyle: {
        color: '#ce0011'
      },
      areaStyle: {
        color: '#ce0011',
        opacity: .25
      }
    },
    borderColor: '#cccccc',
    fillerColor: 'rgba(0,0,0,.1)',
    handleStyle: {
      color: '#ccc',
      borderColor: '#aaa',
    },
    moveHandleStyle: {
      color: '#ccc',
      borderColor: '#aaa'
    },
    emphasis: {
      handleStyle: {
        color: '#ce0011',
        borderColor: '#ce0011',
      },
      moveHandleStyle: {
        color: '#ce0011',
        borderColor: '#ce0011',
      }
    }
  }

  toolboxOptions = {
    show: true,
    right: 10,
    showTitle: false,
    emphasis: {
      iconStyle: {
        color: this.colors[0],
        borderColor: this.colors[0]
      }
    },
    iconStyle: {
      color: '#666',
      borderWidth: 1
    },
    tooltip: { // same as option.tooltip
      confine: true,
      show: true,
      formatter: function (param: {title: string}) {
          return '<div>' + param.title + '</div>'; // user-defined DOM structure
      },
      backgroundColor: '#fff',
      textStyle: {
          fontSize: 12,
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);' // user-defined CSS styles
    }
  }

  resetZoomOptions = {
    show: true,
    title: 'Nulstil zoom til fra- og til-år',
    iconStyle: {
      color: '#666',
      borderWidth: 0
    },
    icon: 'path://M15.7,7.2c-.9,0-1.2.5-1.2,1.1,0,2.2-1,4.3-3,5.3-4.8,2.4-9.5-1.4-9-6s3.1-5.2,6.1-5.2,2.1.2,3.1.8l.4.4-1.1,1.1c-.3.3-.1.8.3.9l4.3.6c.5,0,.9-.3.8-.8l-.7-4.3c0-.4-.5-.5-.7-.2l-1.1,1.1-.4-.3C12.1.5,10.4-.1,8.5-.1,3.5,0-.5,4.3,0,9.5s3.6,7.1,7.5,7.5c3.9.4,9.4-3.4,9.4-8.4s0-.5,0-.6c-.1-.6-.5-.8-1.1-.8Z',
  }

  chartDataZoom = (chart: EChartsType) => {
    chart.dispatchAction({
      type: "dataZoom",
      startValue: `jan. ${this.selectedYearFrom}`,
      endValue: `dec. ${this.selectedYearTo}`,
    })
  }

  saveImageAsOptions = {
    title: 'Gem som billede',
    iconStyle: {
      color: 'transparent',
      borderWidth: 2,
      textFill: '#666'
    },
    emphasis: {
      iconStyle: {
        color: 'transparent',
        textFill: this.colors[0]
      }
    }
  }

  fullScreenIcon = 'path:M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5'
  fullscreenOptions = (fullscreenBoolean: keyof NetPositionsComponent, instance: keyof NetPositionsComponent) => {
    return {
      show: true,
      title: 'Vis/forlad fuldskærm',
      icon: this.fullScreenIcon,
      onclick: () => {
        (this[fullscreenBoolean] as boolean) = !this[fullscreenBoolean] as boolean
        this.cdr.markForCheck()
        setTimeout(() => {
          (this[instance] as EChartsType)?.resize()
        }, 10);
      }
    }
  }

  downloadIcon = 'path:M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293zM14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z'
  downloadNetPositions = (headersString: string, inputData: (string | number)[][], fileName: string, title = 'Download .csv') => {
    return {
      show: true,
      title: title,
      icon: this.downloadIcon,
      onclick: () => {
        let data = `${headersString}\n`
        for (const item of inputData) {
          let i = 1
          let string = ''
          for (const value of item) {
            string += `${value}${i === item.length ? '\n' : ','}`
            i++
          }
          data += string
        }
        const link = document.createElement("a")
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
        link.download = fileName
        link.click()
      }
    }
  }

  dataZoom = [
    {
      id: 'dataZoomX',
      type: 'slider',
      xAxisIndex: [0, 1],
      startValue: `jan. ${this.selectedYearFrom}`,
      endValue: `dec. ${this.selectedYearTo}`,
      filterMode: 'filter',
      realtime: false,
      ...this.dataZoomStyling
    }
  ] as SliderDataZoomComponentOption

  oilB0Data: (string | number)[][] = []
  oilB7Data: (string | number)[][] = []
  electricitySystemData: (string | number)[][] = []
  electricityEpadDk1Data: (string | number)[][] = []
  electricityEpadDk2Data: (string | number)[][] = []
  gasEtfData: (string | number)[][] = []
  gasTtfData: (string | number)[][] = []
  showCharts = false

  ngOnInit(): void {
      this.http.get<{
        oil: {
          b0: (string | number)[][]
          b7: (string | number)[][]
        },
        electricity: {
          system: (string | number)[][]
          'EPAD-DK1': (string | number)[][]
          'EPAD-DK2': (string | number)[][]
        },
        gas: {
          ETF: (string | number)[][]
          TTF: (string | number)[][]
        }
      }>(`${environment.apiConfig.bffBaseUrl}/api/v1/net-positions`, {headers: this.permissions.headers()}).subscribe((resp) => {
        this.oilB0Data = resp.oil.b0
        this.oilB7Data = resp.oil.b7
        this.electricitySystemData = resp.electricity.system
        this.electricityEpadDk1Data = resp.electricity['EPAD-DK1']
        this.electricityEpadDk2Data = resp.electricity['EPAD-DK2']
        this.gasEtfData = resp.gas.ETF
        this.gasTtfData = resp.gas.TTF
        
        /* Oil B0 */
        this.oilB0NetPosition = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => { return `${value?.toLocaleString('da-DK')} ${this.oilSettings.unit}` }
          },
          grid: this.oilSettings.grid,
          xAxis: {
            data: this.oilB0Data.map(function (item) {
              return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
            })
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.oilSettings.unit}` }
            },
            boundaryGap: ['15%', '15%']
          },
          toolbox: {
            ...this.toolboxOptions,
            feature: {
              myResetZoom: {
                ...this.resetZoomOptions,
                onclick: () => this.chartDataZoom(this.oilB0NetPositionInstance as EChartsType)
              },
              saveAsImage: this.saveImageAsOptions,
              myFullScreen: this.fullscreenOptions('oilB0NetPositionFullScreen', 'oilB0NetPositionInstance'),
              myDownloadCSV: this.downloadNetPositions('date,nettoposition', this.oilB0Data, 'Nettoposition på B0.csv') 
            }
          },
          dataZoom: this.dataZoom,
          visualMap: this.visualMapSettings,
          series: {
            name: 'Nettoposition',
            type: 'line',
            areaStyle: {
              opacity: .25
            },
            data: this.oilB0Data.map(function (item: (number | string)[]) {
              return item[1];
            })
          }
        }

        /* Oil B7 */
        this.oilB7NetPosition = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => { return `${value?.toLocaleString('da-DK')} ${this.oilSettings.unit}` }
          },
          grid: this.oilSettings.grid,
          xAxis: {
            data: this.oilB7Data.map(function (item) {
              return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
            })
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.oilSettings.unit}` }
            },
            boundaryGap: ['15%', '15%']
          },
          toolbox: {
            ...this.toolboxOptions,
            feature: {
              myResetZoom: {
                ...this.resetZoomOptions,
                onclick: () => this.chartDataZoom(this.oilB7NetPositionInstance as EChartsType)
              },
              saveAsImage: this.saveImageAsOptions,
              myFullScreen: this.fullscreenOptions('oilB7NetPositionFullScreen', 'oilB7NetPositionInstance'),
              myDownloadCSV: this.downloadNetPositions('date,nettoposition', this.oilB7Data, 'Nettoposition på B7.csv') 
            }
          },
          dataZoom: this.dataZoom,
          visualMap: this.visualMapSettings,
          series: [
            {
              name: 'Nettoposition',
              type: 'line',
              areaStyle: {
                opacity: .25
              },
              data: this.oilB7Data.map(function (item: (number | string)[]) {
                return item[1];
              }),
            },
            // {
            //   name: 'Købt',
            //   type: 'line',
            //   data: this.oilB7Data.map(function (item: (number | string)[]) {
            //     return item[2];
            //   }),
            // },
            // {
            //   name: 'Solgt',
            //   type: 'line',
            //   data: this.oilB7Data.map(function (item: (number | string)[]) {
            //     return item[3];
            //   }),
            // }
          ]
        }

        /* Electricity System */
        this.electricitySystemNetPosition = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => { return `${value?.toLocaleString('da-DK')} ${this.electricitySettings.unit}` }
          },
          grid: this.electricitySettings.grid,
          xAxis: {
            data: this.electricitySystemData.map(function (item) {
              return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
            })
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.electricitySettings.unit}` }
            },
            boundaryGap: ['15%', '15%']
          },
          toolbox: {
            ...this.toolboxOptions,
            feature: {
              myResetZoom: {
                ...this.resetZoomOptions,
                onclick: () => this.chartDataZoom(this.electricitySystemInstance as EChartsType)
              },
              saveAsImage: this.saveImageAsOptions,
              myFullScreen: this.fullscreenOptions('electricitySystemFullScreen', 'electricitySystemInstance'),
              myDownloadCSV: this.downloadNetPositions('date,nettoposition', this.electricitySystemData, 'Nettoposition på El - System.csv') 
            }
          },
          dataZoom: this.dataZoom,
          visualMap: this.visualMapSettings,
          series: {
            name: 'Nettoposition',
            type: 'line',
            areaStyle: {
              opacity: .25
            },
            data: this.electricitySystemData.map(function (item: (number | string)[]) {
              return item[1];
            })
          }
        }

        /* Electricity EPAD DK1 */
        this.electricityEpadDk1NetPosition = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => { return `${value?.toLocaleString('da-DK')} ${this.electricitySettings.unit}` }
          },
          grid: this.electricitySettings.grid,
          xAxis: {
            data: this.electricityEpadDk1Data.map(function (item) {
              return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
            })
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.electricitySettings.unit}` }
            },
            boundaryGap: ['15%', '15%']
          },
          toolbox: {
            ...this.toolboxOptions,
            feature: {
              myResetZoom: {
                ...this.resetZoomOptions,
                onclick: () => this.chartDataZoom(this.electricityEpadDk1Instance as EChartsType)
              },
              saveAsImage: this.saveImageAsOptions,
              myFullScreen: this.fullscreenOptions('electricityEpadDk1FullScreen', 'electricityEpadDk1Instance'),
              myDownloadCSV: this.downloadNetPositions('date,nettoposition', this.electricityEpadDk1Data, 'Nettoposition på El - EPAD DK1.csv') 
            }
          },
          dataZoom: this.dataZoom,
          visualMap: this.visualMapSettings,
          series: {
            name: 'Nettoposition',
            type: 'line',
            areaStyle: {
              opacity: .25
            },
            data: this.electricityEpadDk1Data.map(function (item: (number | string)[]) {
              return item[1];
            })
          }
        }

        /* Electricity EPAD DK2 */
        this.electricityEpadDk2NetPosition = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => { return `${value?.toLocaleString('da-DK')} ${this.electricitySettings.unit}` }
          },
          grid: this.electricitySettings.grid,
          xAxis: {
            data: this.electricityEpadDk2Data.map(function (item) {
              return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
            })
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.electricitySettings.unit}` }
            },
            boundaryGap: ['15%', '15%']
          },
          toolbox: {
            ...this.toolboxOptions,
            feature: {
              myResetZoom: {
                ...this.resetZoomOptions,
                onclick: () => this.chartDataZoom(this.electricityEpadDk2Instance as EChartsType)
              },
              saveAsImage: this.saveImageAsOptions,
              myFullScreen: this.fullscreenOptions('electricityEpadDk2FullScreen', 'electricityEpadDk2Instance'),
              myDownloadCSV: this.downloadNetPositions('date,nettoposition', this.electricityEpadDk2Data, 'Nettoposition på El - EPAD DK2.csv') 
            }
          },
          dataZoom: this.dataZoom,
          visualMap: this.visualMapSettings,
          series: {
            name: 'Nettoposition',
            type: 'line',
            areaStyle: {
              opacity: .25
            },
            data: this.electricityEpadDk2Data.map(function (item: (number | string)[]) {
              return item[1];
            })
          }
        }

        /* Gas ETF */
        this.gasEtfNetPosition = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => { return `${value?.toLocaleString('da-DK')} ${this.gasSettings.unit}` }
          },
          grid: this.gasSettings.grid,
          xAxis: {
            data: this.gasEtfData.map(function (item) {
              return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
            })
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.gasSettings.unit}` }
            },
            boundaryGap: ['15%', '15%']
          },
          toolbox: {
            ...this.toolboxOptions,
            feature: {
              myResetZoom: {
                ...this.resetZoomOptions,
                onclick: () => this.chartDataZoom(this.gasEtfInstance as EChartsType)
              },
              saveAsImage: this.saveImageAsOptions,
              myFullScreen: this.fullscreenOptions('gasEtfFullScreen', 'gasEtfInstance'),
              myDownloadCSV: this.downloadNetPositions('date,nettoposition', this.gasEtfData, 'Nettoposition på Gas - ETF.csv') 
            }
          },
          dataZoom: this.dataZoom,
          visualMap: this.visualMapSettings,
          series: {
            name: 'Nettoposition',
            type: 'line',
            areaStyle: {
              opacity: .25
            },
            data: this.gasEtfData.map(function (item: (number | string)[]) {
              return item[1];
            })
          }
        }

        /* Gas TTF */
        this.gasTtfNetPosition = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => { return `${value?.toLocaleString('da-DK')} ${this.gasSettings.unit}` }
          },
          grid: this.gasSettings.grid,
          xAxis: {
            data: this.gasTtfData.map(function (item) {
              return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
            })
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.gasSettings.unit}` }
            },
            boundaryGap: ['15%', '15%']
          },
          toolbox: {
            ...this.toolboxOptions,
            feature: {
              myResetZoom: {
                ...this.resetZoomOptions,
                onclick: () => this.chartDataZoom(this.gasTtfInstance as EChartsType)
              },
              saveAsImage: this.saveImageAsOptions,
              myFullScreen: this.fullscreenOptions('gasTtfFullScreen', 'gasTtfInstance'),
              myDownloadCSV: this.downloadNetPositions('date,nettoposition', this.gasTtfData, 'Nettoposition på Gas - TTF.csv') 
            }
          },
          dataZoom: this.dataZoom,
          visualMap: this.visualMapSettings,
          series: {
            name: 'Nettoposition',
            type: 'line',
            areaStyle: {
              opacity: .25
            },
            data: this.gasTtfData.map(function (item: (number | string)[]) {
              return item[1];
            })
          }
        }

        this.showCharts = true
      })
  }
}
