import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { PermissionsService } from './permissions.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor(
    private http: HttpClient,
    private permissions: PermissionsService
  ) {}

  private handleError(error: ErrorEvent) {
    console.error('There was a problem', error)
    return throwError(() => new Error('Oops! Something went wrong. Please try again later.'));
  }

  getProfiles(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiConfig.bffBaseUrl}/api/v1/profiles`, {headers: this.permissions.headers()}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }
}
