<div class="grid grid-cols-3 gap-4">
  <div class="flex gap-4 flex-col">
    <h2 class="text-2xl font-bold text-secondary-dark">Olie</h2>

    <app-card
      [class]="oilB0NetPositionFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
      cardClass="h-full !overflow-visible">
      <h3 class="font-bold text-xl text-secondary-dark mb-4">
        Nettoposition på B0
      </h3>
      @if (showCharts) {
      <div echarts (chartInit)="oilB0NetPositionInstance = $event" [options]="oilB0NetPosition" [autoResize]="true"
        class="aspect-[4/3] relative" [class]="oilB0NetPositionFullScreen ? 'w-full max-h-[90vh]' : ''">
      </div>
      }
    </app-card>

    <app-card
      [class]="oilB7NetPositionFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
      cardClass="h-full !overflow-visible">
      <h3 class="font-bold text-xl text-secondary-dark mb-4">
        Nettoposition på B7
      </h3>
      @if (showCharts) {
      <div echarts (chartInit)="oilB7NetPositionInstance = $event" [options]="oilB7NetPosition" [autoResize]="true"
        class="aspect-[4/3] relative" [class]="oilB7NetPositionFullScreen ? 'w-full max-h-[90vh]' : ''">
      </div>
      }
    </app-card>

  </div>
  <div class="flex flex-col gap-4">
    <h2 class="text-2xl font-bold text-secondary-dark">El</h2>

    <app-card
      [class]="electricitySystemFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
      cardClass="h-full !overflow-visible">
      <h3 class="font-bold text-xl text-secondary-dark mb-4">
        Nettoposition på El - System
      </h3>
      @if (showCharts) {
      <div echarts (chartInit)="electricitySystemInstance = $event" [options]="electricitySystemNetPosition"
        [autoResize]="true" class="aspect-[4/3] relative"
        [class]="electricitySystemFullScreen ? 'w-full max-h-[90vh]' : ''"></div>
      }
    </app-card>

    <app-card
      [class]="electricityEpadDk1FullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
      cardClass="h-full !overflow-visible">
      <h3 class="font-bold text-xl text-secondary-dark mb-4">
        Nettoposition på El - EPAD DK1
      </h3>
      @if (showCharts) {
      <div echarts (chartInit)="electricityEpadDk1Instance = $event" [options]="electricityEpadDk1NetPosition"
        [autoResize]="true" class="aspect-[4/3] relative"
        [class]="electricityEpadDk1FullScreen ? 'w-full max-h-[90vh]' : ''"></div>
      }
    </app-card>

    <app-card
      [class]="electricityEpadDk2FullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
      cardClass="h-full !overflow-visible">
      <h3 class="font-bold text-xl text-secondary-dark mb-4">
        Nettoposition på El - EPAD DK2
      </h3>
      @if (showCharts) {
      <div echarts (chartInit)="electricityEpadDk2Instance = $event" [options]="electricityEpadDk2NetPosition"
        [autoResize]="true" class="aspect-[4/3] relative"
        [class]="electricityEpadDk2FullScreen ? 'w-full max-h-[90vh]' : ''"></div>
      }
    </app-card>

  </div>
  <div class="flex flex-col gap-4">
    <h2 class="text-2xl font-bold text-secondary-dark">Gas</h2>
    <app-card
      [class]="gasEtfFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
      cardClass="h-full !overflow-visible">
      <h3 class="font-bold text-xl text-secondary-dark mb-4">
        Nettoposition på Gas - ETF
      </h3>
      @if (showCharts) {
      <div echarts (chartInit)="gasEtfInstance = $event" [options]="gasEtfNetPosition" [autoResize]="true"
        class="aspect-[4/3] relative" [class]="gasEtfFullScreen ? 'w-full max-h-[90vh]' : ''"></div>
      }
    </app-card>
    <app-card
      [class]="gasTtfFullScreen ? 'fixed left-0 right-0 top-[60px] bottom-0 z-50' : 'relative left-0 right-0 top-0 bottom-0'"
      cardClass="h-full !overflow-visible">
      <h3 class="font-bold text-xl text-secondary-dark mb-4">
        Nettoposition på Gas - TTF
      </h3>
      @if (showCharts) {
      <div echarts (chartInit)="gasTtfInstance = $event" [options]="gasTtfNetPosition" [autoResize]="true"
        class="aspect-[4/3] relative" [class]="gasTtfFullScreen ? 'w-full max-h-[90vh]' : ''"></div>
      }
    </app-card>
  </div>
</div>