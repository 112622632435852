import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { EChartsOption, EChartsType, SeriesOption, SliderDataZoomComponentOption, TooltipComponentOption } from 'echarts';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { CardComponent } from "../../components/card.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DashboardTableComponent } from "../../components/dashboardTable.component";
import { HttpClient } from '@angular/common/http';
import { PermissionsService } from '../../services/permissions.service';
import { environment } from '../../../environments/environment';

interface DashboardData {
  oilSalesData: (string | number)[][]
  oilSalesMonthData: (string | number[])[][]
  electricitySalesData: (string | number)[][]
  electricitySalesMonthData: (string | number[])[][]
  gasSalesData: (string | number)[][]
  gasSalesMonthData: (string | number[])[][]
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [],
  standalone: true,
  imports: [NgxEchartsDirective, CardComponent, MatFormFieldModule, MatSelectModule, DashboardTableComponent],
  providers: [provideEcharts()]
})
export class HomeComponent implements OnInit{
  private cdr = inject(ChangeDetectorRef)

  constructor(
    private http: HttpClient,
    private permissions: PermissionsService
  ){}

  years: number[] = [2023, 2024, 2025, 2026, 2027]
  selectedYearFrom = 2023
  selectedYearTo = 2027
  now = new Date().getFullYear

  chartLegendSelect = (chart: EChartsType) => {
    for (const year of this.years) {
      if (year >= this.selectedYearFrom && year <= this.selectedYearTo) {
        chart.dispatchAction({
          type: 'legendSelect',
          name: `${year}`,
        });
      } else {
        chart.dispatchAction({
          type: 'legendUnSelect',
          name: `${year}`,
        });
      }
    }
  }

  chartDataZoom = (chart: EChartsType) => {
    chart.dispatchAction({
      type: "dataZoom",
      startValue: `jan. ${this.selectedYearFrom}`,
      endValue: `dec. ${this.selectedYearTo}`,
    })
  }

  setZoom = () => {
    this.chartDataZoom(this.oilSalesInstance as EChartsType)
    this.chartDataZoom(this.electricitySalesInstance as EChartsType)
    this.chartDataZoom(this.gasSalesInstance as EChartsType)
    this.chartLegendSelect(this.oilSalesMonthInstance as EChartsType)
    this.chartLegendSelect(this.electricitySalesMonthInstance as EChartsType)
    this.chartLegendSelect(this.gasSalesMonthInstance as EChartsType)
  }

  getClosedTradesHeaders = (unit: string) => {
    return ['', 'Antal', `Volumen, ${unit}`]
  }

  closedTradesData = [
    {
      title: 'I dag',
      count: 10,
      volume: 400
    },
    {
      title: 'Seneste 7 dage',
      count: 75,
      volume: 2500
    },
    {
      title: 'Seneste 30 dage',
      count: 250,
      volume: 4000
    },
    {
      title: 'ÅTD',
      count: 500,
      volume: 9000
    }
  ]

  fullScreenIcon = 'path:M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5'
  fullscreenOptions = (fullscreenBoolean: keyof HomeComponent, instance: keyof HomeComponent) => {
    return {
      show: true,
      title: 'Vis/forlad fuldskærm',
      icon: this.fullScreenIcon,
      onclick: () => {
        (this[fullscreenBoolean] as boolean) = !this[fullscreenBoolean] as boolean
        this.cdr.markForCheck()
        setTimeout(() => {
          (this[instance] as EChartsType)?.resize()
        }, 10);
      }
    }
  }

  downloadIcon = 'path:M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293zM14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z'
  downloadSalesOptions = (headersString: string, inputData: (string | number)[][], fileName: string, title = 'Download .csv') => {
    return {
      show: true,
      title: title,
      icon: this.downloadIcon,
      onclick: () => {
        let data = `${headersString}\n`
        for (const item of inputData) {
          let i = 1
          let string = ''
          for (const value of item) {
            string += `${value}${i === item.length ? '\n' : ','}`
            i++
          }
          data += string
        }
        const link = document.createElement("a")
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
        link.download = fileName
        link.click()
      }
    }
  }
  downloadsalesMonthOptions = (inputData: (string | (number | null)[])[][], fileName: string, title = 'Download .csv') => {
    return {
      show: true,
      title: title,
      icon: this.downloadIcon,
      onclick: () => {
        const months = ['jan.', 'feb.', 'mar.', 'apr.', 'maj', 'jun.', 'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.']
        let headers = 'måned'
        for (const item of inputData) {
          headers += (`,${item[0]}`)
        }
        headers += '\n'
        let series = ''
        let i = 0
        for (const month of months) {
          series += month
          for (const item of inputData) {
            series += `,${item[1][i]}`
          }
          series+= '\n'
          i++
        }
        const data = headers + series
        const link = document.createElement("a")
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
        link.download = fileName
        link.click()
      }
    }
  }
  /* OIL */
  oilSettings = {
    unit: 'm³',
    marginLeft: 60
  }
  oilSales: EChartsOption = {}
  oilSalesInstance: EChartsType | null = null
  oilSalesFullScreen = false
  oilSalesMonth: EChartsOption = {}
  oilSalesMonthInstance: EChartsType | null = null
  oilSalesMonthFullScreen = false

  /* Electricity */
  electricitySettings = {
    unit: 'kWh',
    marginLeft: 95
  }
  electricitySales: EChartsOption = {}
  electricitySalesInstance: EChartsType | null = null
  electricitySalesFullScreen = false
  electricitySalesMonth: EChartsOption = {}
  electricitySalesMonthInstance: EChartsType | null = null
  electricitySalesMonthFullScreen = false

  /* Gas */
  gasSettings = {
    unit: 'm³',
    marginLeft: 75
  }
  gasSales: EChartsOption = {}
  gasSalesInstance: EChartsType | null = null
  gasSalesFullScreen = false
  gasSalesMonth: EChartsOption = {}
  gasSalesMonthInstance: EChartsType | null = null
  gasSalesMonthFullScreen = false

  colors = [
    '#ce0011',
    '#25AD8D',
    '#F39F2D',
    '#78A2BB',
  ]

  toolboxOptions = {
    show: true,
    showTitle: false,
    emphasis: {
      iconStyle: {
        color: this.colors[0],
        borderColor: this.colors[0]
      }
    },
    iconStyle: {
      color: '#666',
      borderWidth: 1
    },
    tooltip: { // same as option.tooltip
      confine: true,
      show: true,
      formatter: function (param: {title: string}) {
          return '<div>' + param.title + '</div>'; // user-defined DOM structure
      },
      backgroundColor: '#fff',
      textStyle: {
          fontSize: 12,
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);' // user-defined CSS styles
    }
  }

  saveImageAsOptions = {
    title: 'Gem som billede',
    iconStyle: {
      color: 'transparent',
      borderWidth: 2,
      textFill: '#666'
    },
    emphasis: {
      iconStyle: {
        color: 'transparent',
        textFill: this.colors[0]
      }
    }
  }

  resetZoomOptions = {
    show: true,
    title: 'Nulstil zoom til fra- og til-år',
    iconStyle: {
      color: '#666',
      borderWidth: 0
    },
    icon: 'path://M15.7,7.2c-.9,0-1.2.5-1.2,1.1,0,2.2-1,4.3-3,5.3-4.8,2.4-9.5-1.4-9-6s3.1-5.2,6.1-5.2,2.1.2,3.1.8l.4.4-1.1,1.1c-.3.3-.1.8.3.9l4.3.6c.5,0,.9-.3.8-.8l-.7-4.3c0-.4-.5-.5-.7-.2l-1.1,1.1-.4-.3C12.1.5,10.4-.1,8.5-.1,3.5,0-.5,4.3,0,9.5s3.6,7.1,7.5,7.5c3.9.4,9.4-3.4,9.4-8.4s0-.5,0-.6c-.1-.6-.5-.8-1.1-.8Z',
  }

  dataZoomStyling = {
    backgroundColor: 'white',
    dataBackground: {
      lineStyle: {
        color: '#ce0011'
      },
      areaStyle: {
        color: '#ce0011',
        opacity: .05
      }
    },
    selectedDataBackground: {
      lineStyle: {
        color: '#ce0011'
      },
      areaStyle: {
        color: '#ce0011',
        opacity: .25
      }
    },
    borderColor: '#cccccc',
    fillerColor: 'rgba(0,0,0,.1)',
    handleStyle: {
      color: '#ccc',
      borderColor: '#aaa',
    },
    moveHandleStyle: {
      color: '#ccc',
      borderColor: '#aaa'
    },
    emphasis: {
      handleStyle: {
        color: '#ce0011',
        borderColor: '#ce0011',
      },
      moveHandleStyle: {
        color: '#ce0011',
        borderColor: '#ce0011',
      }
    }
  }

  dataZoom = [
    {
      id: 'dataZoomX',
      type: 'slider',
      xAxisIndex: [0, 1],
      startValue: `jan. ${this.selectedYearFrom}`,
      endValue: `dec. ${this.selectedYearTo}`,
      filterMode: 'filter',
      realtime: false,
      ...this.dataZoomStyling
    }
  ] as SliderDataZoomComponentOption

  getOptionsFromSettings = (settings: {unit: string; marginLeft: number}, chartType: 'bar' | 'line' = 'line') => {
    return {
      grid: {
        ...(chartType === 'bar' ? {bottom: 20} : null),
        right: chartType === 'line' ? '4%' : '2%',
        left: settings.marginLeft
      },
      color: this.colors,
      legend: {},
      tooltip: {
        ...(chartType === 'line' ? {trigger: 'axis'} : null),
        valueFormatter: (value: number) => { return `${value?.toLocaleString('da-DK')} ${settings.unit}` }
      } as TooltipComponentOption,
    }
  }

  createSeries = (items: (string | (number | null)[])[][]) => {
    const series: SeriesOption[] = []
    for (const item of items) {
      series.push({
        type: 'bar',
        name: item[0] as string,
        data: item[1] as number[]
      })
    }
    return series
  }

  data: DashboardData = {
    electricitySalesData: [],
    electricitySalesMonthData:[],
    gasSalesData: [],
    gasSalesMonthData: [],
    oilSalesData: [],
    oilSalesMonthData: []
  }

  showGraphs = false

  ngOnInit(): void {
    // this.permissions.waitForToken().then(() => {
    //   console.log('token')
    // })
    this.http.get<DashboardData>(`${environment.apiConfig.bffBaseUrl}/api/v1/dashboard-data`, {headers: this.permissions.headers()}).subscribe((resp) => {
      this.data = resp
      this.showGraphs = true
      this.oilSales = {
        ...this.getOptionsFromSettings(this.oilSettings),
        xAxis: {
          data: this.data.oilSalesData?.map((item) => { 
            return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
          })
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.oilSettings.unit}` }
          }
        },
        series: [
          {
            name: 'B0',
            data: this.data.oilSalesData?.map(function (item) {
              return item[1];
            }),
            type: 'line'
          },
          {
            name: 'B7',
            data: this.data.oilSalesData?.map(function (item) {
              return item[2];
            }),
            type: 'line',
          },
        ],
        dataZoom: this.dataZoom,
        toolbox: {
          ...this.toolboxOptions,
          feature: {
            myResetZoom: {
              ...this.resetZoomOptions,
              onclick: () => this.chartDataZoom(this.oilSalesInstance as EChartsType)
            },
            saveAsImage: this.saveImageAsOptions,
            myFullScreen: this.fullscreenOptions('oilSalesFullScreen', 'oilSalesInstance'),
            myDownloadCSV: this.downloadSalesOptions('date,B0,B7', this.data.oilSalesData, 'Solgt olie på fastpris til fremtidig levering.csv') 
          }
        }
      }
  
      this.oilSalesMonth = {
        ...this.getOptionsFromSettings(this.oilSettings, 'bar'),
        xAxis: { 
          type: 'category',
          data: ['jan.', 'feb.', 'mar.', 'apr.', 'maj', 'jun.', 'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.']
        },
        yAxis: {
          axisLabel: {
            formatter: (value: number) => { return `${value.toLocaleString('da-DK')} ${this.oilSettings.unit}` }
          }
        },
        series: this.createSeries(this.data.oilSalesMonthData),
        toolbox: {
          ...this.toolboxOptions,
          feature: {
            myResetZoom: {
              ...this.resetZoomOptions,
              onclick: () => this.chartLegendSelect(this.oilSalesMonthInstance as EChartsType)
            },
            saveAsImage: this.saveImageAsOptions,
            myFullScreen: this.fullscreenOptions('oilSalesMonthFullScreen', 'oilSalesMonthInstance'),
            myDownloadCSV: this.downloadsalesMonthOptions(this.data.oilSalesMonthData, 'Solgt olie volumen efter salgsmåned.csv')
          }
        }
      }
  
      this.electricitySales = {
        ...this.getOptionsFromSettings(this.electricitySettings),
        series: [
          {
            name: '',
            data: this.data.electricitySalesData.map(function (item) {
              return item[1];
            }),
            type: 'line',
          }
        ],
        xAxis: {
          data: this.data.electricitySalesData.map((item) => { 
            return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
          })
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value) => { return `${value.toLocaleString('da-DK')} kWh` }
          }
        },
        dataZoom: this.dataZoom,
        toolbox: {
          ...this.toolboxOptions,
          feature: {
            myResetZoom: {
              ...this.resetZoomOptions,
              onclick: () => this.chartDataZoom(this.electricitySalesInstance as EChartsType)
            },
            saveAsImage: this.saveImageAsOptions,
            myFullScreen: this.fullscreenOptions('electricitySalesFullScreen', 'electricitySalesInstance'),
            myDownloadCSV: this.downloadSalesOptions('date,amount', this.data.electricitySalesData, 'Solgt el på fastpris til fremtidig levering.csv') 
          }
        }
      }
  
      this.electricitySalesMonth = {
        ...this.getOptionsFromSettings(this.electricitySettings, 'bar'),
        xAxis: { 
          type: 'category',
          data: ['jan.', 'feb.', 'mar.', 'apr.', 'maj', 'jun.', 'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.']
        },
        yAxis: {
          axisLabel: {
            formatter: (value: number) => { return `${value.toLocaleString('da-DK')} kWh` }
          }
        },
        series: this.createSeries(this.data.electricitySalesMonthData),
        toolbox: {
          ...this.toolboxOptions,
          feature: {
            myResetZoom: {
              ...this.resetZoomOptions,
              onclick: () => this.chartLegendSelect(this.electricitySalesMonthInstance as EChartsType)
            },
            saveAsImage: this.saveImageAsOptions,
            myFullScreen: this.fullscreenOptions('electricitySalesMonthFullScreen', 'electricitySalesMonthInstance'),
            myDownloadCSV: this.downloadsalesMonthOptions(this.data.electricitySalesMonthData, 'Solgt el volumen efter salgsmåned.csv')
          }
        }
      }
  
      this.gasSales = {
        ...this.getOptionsFromSettings(this.gasSettings),
        series: [
          {
            name: '',
            data: this.data.gasSalesData.map(function (item) {
              return item[1];
            }),
            type: 'line',
          }
        ],
        xAxis: {
          data: this.data.gasSalesData.map((item) => { 
            return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
          })
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value) => { return `${value.toLocaleString('da-DK')} m³` }
          }
        },
        dataZoom: this.dataZoom,
        toolbox: {
          ...this.toolboxOptions,
          feature: {
            myResetZoom: {
              ...this.resetZoomOptions,
              onclick: () => this.chartDataZoom(this.gasSalesInstance as EChartsType)
            },
            saveAsImage: this.saveImageAsOptions,
            myFullScreen: this.fullscreenOptions('gasSalesFullScreen', 'gasSalesInstance'),
            myDownloadCSV: this.downloadSalesOptions('date,amount', this.data.gasSalesData, 'Solgt gas på fastpris til fremtidig levering.csv')
          }
        }
      }
  
      this.gasSalesMonth = {
        ...this.getOptionsFromSettings(this.gasSettings, 'bar'),
        xAxis: { 
          type: 'category',
          data: ['jan.', 'feb.', 'mar.', 'apr.', 'maj', 'jun.', 'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.']
        },
        yAxis: {
          axisLabel: {
            formatter: (value: number) => { return `${value.toLocaleString('da-DK')} m³` }
          }
        },
        series: this.createSeries(this.data.gasSalesMonthData),
        toolbox: {
          ...this.toolboxOptions,
          feature: {
            myResetZoom: {
              ...this.resetZoomOptions,
              onclick: () => this.chartLegendSelect(this.gasSalesMonthInstance as EChartsType)
            },
            saveAsImage: this.saveImageAsOptions,
            myFullScreen: this.fullscreenOptions('gasSalesMonthFullScreen', 'gasSalesMonthInstance'),
            myDownloadCSV: this.downloadsalesMonthOptions(this.data.gasSalesMonthData, 'Solgt gas volumen efter salgsmåned.csv')
          }
        }
      }
    })
  }
}
